import React from "react"

/*** COMPONENTS ***/
import Main from "../../components/main"
import SEO from "../../components/seo"
import "../../styles/project.css"
import rollercoasterHeader from "../../images/rollercoastertycoon.png"

/*** CONSTANTS ***/
import * as ROUTES from "../../constants/routes"

function openGithubProceduralPostIt() {
  window.open(
    "https://github.com/johannagranstroem/TDDD27-Advanced-Web-Programming",
    "_blank"
  )
}

const rollercoasterPage = () => (
  <Main>
    <SEO title="Rolelr Coaster Simulation" />
    <div class="project-container">
      <div class="project-grid-item-11">
        <img alt="Header-PostIt" src={rollercoasterHeader} />
        <div class="project-circle">
          <p class="project-header-text-black">ROLLER COASTER</p>
          <p class="project-header-text-grey">SIMULATION</p>
        </div>
      </div>
      <div class="project-grid-item-21">
        <p class="project-bulletpoint-text">Language</p>
        <p class="project-bulletpoint-text"> &#9642; MATLAB</p>
      </div>
      <div class="project-grid-item-22">
        <p class="project-bulletpoint-text">Status</p>
        <p class="project-bulletpoint-text"> &#8226; Completed 2018-03-08</p>
      </div>
      <div class="project-grid-item-31">
        <p class="project-text-body-dark">
          This project was done in a group of five, with the aim to make a
          roller coaster simulation. The system was simulated in MATLAB with the
          help of differential equations. We, later on, used Unity to build and
          create the graphics for the simulation. To make an interactive
          simulation we also implemented a user interface where the user could
          change the initial values for the elements such as mass for the
          stroller, gravity, friction and speed.
          <br />
          <br />
          You can checkout the project on{" "}
          <button
            class="project-text-link-style"
            onClick={() => openGithubProceduralPostIt()}
          >
            Github
          </button>
          !
          <br />
          <br />
        </p>
      </div>
      <div class="project-grid-item-61">
        <a class="project-text-back-to-projects" href={ROUTES.PROJECTS}>
          &#8592; Back to projects
        </a>
      </div>
    </div>
  </Main>
)

export default rollercoasterPage
